import { useLink } from "solito/link";

import { BackgroundRadialGrantedGradient } from "@medbillai/app/components/BackgroundRadialGrantedGradient";
import { GrantedLogo } from "@medbillai/app/components/local-images";

import {
  ExternalLink,
  LoadingButton,
  Paragraph,
  Subheadline2,
  YStack,
} from "@medbillai/ui";

export default function Custom404() {
  const loginLink = useLink({ href: "/login" });
  return (
    <YStack f={1} ai="center">
      <BackgroundRadialGrantedGradient />
      <YStack zIndex={40} mt="$10" height="$10">
        <GrantedLogo height={100} width={100} />
      </YStack>
      <YStack
        f={1}
        justifyContent="flex-start"
        alignItems="center"
        pt="$10"
        px="$4.5"
        maxWidth={600}
        width="100%"
      >
        <YStack
          shadowColor="$shadowColor"
          shadowOffset={{ width: 0, height: 5 }}
          shadowOpacity={1}
          shadowRadius={6}
          br="$8"
          width="100%"
        >
          <YStack
            gap="$3"
            justifyContent="center"
            backgroundColor="$background"
            borderWidth={1}
            borderColor="$strokeSecondary"
            p="$9"
            $sm={{
              p: "$4",
            }}
            br="$8"
            alignItems="center"
            width="100%"
          >
            <Subheadline2 textAlign="center">
              Sorry, that page cannot be found.
            </Subheadline2>
            <Paragraph theme="alt1" textAlign="center">
              The page you’re looking for does not exist. Please check the URL
              or log back in using the link below.
            </Paragraph>
            <Paragraph theme="alt1" textAlign="center">
              If you believe this is an error, please{" "}
              <ExternalLink
                href="mailto:support@medbill.ai"
                title="contact us"
              />
              .
            </Paragraph>
            <LoadingButton mt="$4" {...loginLink} f={1} width="100%">
              Login
            </LoadingButton>
          </YStack>
        </YStack>
      </YStack>
    </YStack>
  );
}
