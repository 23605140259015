import { Linking } from "react-native";
import { useLink } from "solito/link";
import {
  type AnchorExtraProps,
  type SizableTextProps,
  type TamaguiElement,
  isWeb,
} from "tamagui";

import { previewUrl } from "@medbillai/utils";

import { AnchorText } from "./Typography";

export type AnchorProps = SizableTextProps &
  AnchorExtraProps & { isPreviewLink?: boolean; title: string; href: string };

export const ExternalLink = AnchorText.styleable<AnchorProps>(
  (
    { href, target, isPreviewLink, title, children, ...props },
    ref: React.RefObject<TamaguiElement>,
  ) => {
    return (
      <AnchorText
        {...props}
        {...(isWeb
          ? {
              href,
              target,
            }
          : isPreviewLink
            ? useLink({
                href: previewUrl({ title, uri: href }),
              })
            : {
                onPress: event => {
                  props.onPress?.(event);
                  void Linking.openURL(href);
                },
              })}
        ref={ref}
      >
        {children ?? title}
      </AnchorText>
    );
  },
);
